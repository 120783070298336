import Cookies from "js-cookie";
import { get_token } from "@/utils/cookie-storage";
import { useEffect } from "react";
import { useRouter } from "next/router";

export default function Home() {
  const router = useRouter();
  useEffect(() => {
    const token = get_token();
    if (token) {
      router.push("/account/dashboard");
    } else {
      router.push("/auth");
    }
  }, [router]);
  return null;
}
